<template>
	<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
		<!-- About profile image -->
		<div class="w-60 sm:w-1/4 mb-7 sm:mb-0">
			<img
				src="@/assets/images/profile.jpeg"
				class="rounded-xl w-96"
				alt=""
			/>
		</div>

		<!-- About details -->
		<div class="w-full sm:w-3/4 text-left">
			<p
				v-for="bio in bios"
				:key="bio.id"
				class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg"
				v-html="processBio(bio.bio)"
			></p>
		</div>
	</div>
</template>

<script>
export default {
	setup() {
		return {
			bios: [
				{
					id: 1,
					bio:
						'Mein Name ist D.I. Ulf Marsche und ich freue mich, hier einen Einblick über mich zu geben:\n\n Nach der HTL für Elektrotechnik entschied ich mich für das Studium der Telematik an der TU Graz.\nDurch meine Diplomarbeit "Das interaktive Bild" kam ich in den Bereich der interaktiven Soft- und Hardware-Entwicklung, in der ich seit vielen Jahre tätig bin.\n\nSeit dem Jahr 2016 bin ich als Softwareentwickler selbstständig, dadurch habe ich die Möglichkeit an einer Vielzahl aufregender Projekte zu arbeiten und meine Kreativität und technischen Fähigkeiten voll auszuschöpfen.',
				},
				{
					id: 2,
					bio:
						'',
				},
			],
		};
	},
	methods: {
		processBio(bio) {
			return bio.replace(/\n/g, '<br>');
		},
	},
};
</script>
