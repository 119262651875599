// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'Carry my car',
		category: 'Frontend & Backend',
		description: 'Hol und Bringservice für Werkstätten: Konzeptionierung von Backend und Frontend des Buchungssystems.',
		img: require('@/assets/images/projects/carry_my_car.jpg'),
	},
	{
		id: 2,
		title: 'BVAEB',
		category: 'Interaktive Installation',
		description: 'Auf dem virtuellen Spieltisch bewegen sich die Spielteilnehmer/innen mit realen Objekten, die sie meist aus dem Alltag kennen. Lebensmittelverpackungen und Lebensmittel- Objekte werden einfach auf den Spieltisch bewegt und mit Hilfe verschiedener Auswertungssteine erhält man Informationen zu Nährstoffe, Kalorien, Zucker, Fett.',
		img: require('@/assets/images/projects/bvaeb.jpg'),
	},
	{
		id: 3,
		title: 'NextSense',
		category: 'Virtual Reality',
		description: 'Virtual Reality Simulator für  NEXTSENSE-Worlwide. Eine Autotür dient als Portal in die VR Welt. Der Benutzer hat die Möglichkeit das mobile Messgerät "Calipri" in der Rolle eines Werkers an einer getakteten Fertigungsstraße zu testen. ',
		img: require('@/assets/images/projects/nextsense2.jpg'),
	},
	{
		id: 4,
		title: 'InnoPhore',
		category: 'Augmented Reality',
		description: 'Repräsentative Enzyme werden über eigene Quartett-Karten auf der App am Handy oder Tablet eingeblendet.',
		img: require('@/assets/images/projects/innophore.jpg'),
	},
	{
		id: 5,
		title: 'EGGER',
		category: 'Mobile Applikation',
		description: 'Alle Wand-, Decken- und Dachaufbauten, die mit EGGER Bauprodukten realisiert werden können, werden in der Konstruktionskatalog App als 3D-Details visualisiert. Die aufwändigen Konstruktionsdetails können dabei von allen Seiten begutachtet werden für einen vollständigen Rundumblick über die einzelnen Aufbauten.',
		img: require('@/assets/images/projects/egger.jpg'),
	},
	{
		id: 6,
		title: 'Wimmer Hartstahl',
		category: 'Virtual Reality',
		description: 'Simulation eines Tunnelaushubes. Über Sensoren wird die Baggersteuerung an die VR-App übertragen, somit hat der Anwender das Gefühl den Bagger in einem Tunnel zu steuern. Installation für die BAUMA 2022.',
		img: require('@/assets/images/projects/wimmer.jpg'),
	},
	{
		id: 7,
		title: 'Fronius / FH Joanneum Graz',
		category: 'Interaktive Installation',
		description: 'Virtual Welding: Dieses System wird für die Ausbildung von Schweißern verwendet, die mit Hilfe eines pädagogischen Lernsystems gefahrlos die erforderlichen Fähigkeiten erwerben können. Entwicklung und Umsetzung einer physikalisch realistischen Schweissnahtbildung.',
		img: require('@/assets/images/projects/fronius.jpg'),
	},
	{
		id: 8,
		title: 'NextSense',
		category: 'Inverse Kinematik',
		description: 'Erstellung einer inverse Kinematik Logik für Roboter für ein Werbe-Video.',
		img: require('@/assets/images/projects/nextsense.jpg'),
	},
	{
		id: 9,
		title: 'Ärztehaus Lend',
		category: 'Web Application',
		description: 'Webseite für das Ärztehaus-Lend',
		img: require('@/assets/images/projects/aerztehaus_lend.jpg'),
	},
];

export default projects;
