<template>
  <div class="app">
    <!-- Button zum Öffnen des Impressums -->
    <div class="flex justify-center">
			<button @click="showImpressum">Impressum</button>
	</div>

    <!-- Das modale Fenster für das Impressum -->
    <div v-if="isImpressumVisible" class="modal-overlay" @click="hideImpressum">
      <div class="modal" @click.stop>
        <span class="close" @click="hideImpressum">&times;</span>
        <b><h2>Impressum</h2></b>
        <div class="modal-content">
            <br>			
            ViMotion e.U.<br>
            <br>
            Dipl.-Ing. Ulf Marsche<br>
            <br>
            Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz<br>
            <br>
            Unternehmensgegenstand: IT Dienstleistungen<br>
            UID-Nummer: ATU77634516<br>
            Firmensitz: Graz<br>
            <br>
            Eduard-Keil-Gasse 27, 8041 Graz, Österreich<br>
            <br>
            Tel.: +43 660 1884125<br>
            E-Mail: office@vimotion.at<br>
            <br>
            Mitglied bei: Wirtschaftskammer Steiermark<br>
            Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at<br>
            <br>
            Aufsichtsbehörde/Gewerbebehörde: Magistrat der Stadt Graz<br>
            Berufsbezeichnung: Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik<br>
            Verleihungsstaat: Österreich<br>
            <br>
            Angaben zur Online-Streitbeilegung: Verbraucher haben die Möglichkeit, Beschwerden an die OnlineStreitbeilegungsplattform der EU zu richten: http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.<br>
            <br>
            Haftungsausschluss<br>
            <br>
            Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Webseite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.<br>
            <br>
            Haftung für Inhalte dieser Webseite<br>
            <br>
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Wir sind jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br>
            <br>
            Haftung für Links auf Webseiten Dritter<br>
            <br>
            Unser Angebot enthält Links zu externen Websites. Auf den Inhalt dieser externen Webseiten haben wir keinerlei Einfluss. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br>
            <br>
            Urheberrecht<br>
            <br>
            Die Betreiber dieser Webseite sind bemüht, stets die Urheberrechte anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen. Die durch die Seitenbetreiber erstellten Inhalte und Werke auf dieser Webseite unterliegen dem Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br>
            <br>       
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isImpressumVisible: false
    };
  },
  methods: {
    showImpressum() {
      this.isImpressumVisible = true;
    },
    hideImpressum() {
      this.isImpressumVisible = false;
    }
  }
};
</script>

<style>
/* Stilisierung des modalen Fensters */
.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999; /* Stelle sicher, dass das Modal immer über dem restlichen Inhalt liegt */
}

.modal {
  background-color: #fff;
  padding: 20px;
  max-width: 800px;
  border-radius: 5px;
  position: relative;
}

.modal-content {
  max-height: 400px; /* Setze hier die maximale Höhe, die das Modal haben soll */
  overflow-y: auto; /* Füge eine Scrollbar hinzu, wenn der Inhalt zu lang ist */
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

/* Stilisierung des restlichen Inhalts */
.app {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

button {
  display: block;
  margin: 20px auto;
}
</style>
