<template>
	<div
	class="container mx-auto flex flex-col-reverse md:flex-row md:py-10 md:mt-10 justify-center"
	>
		<!-- Contact form -->
		<!-- <ContactForm /> -->

		<!-- Contact details -->
		<ContactDetails :contacts="contacts" />
	</div>
</template>

<script>
import feather from 'feather-icons';
//import ContactForm from '@/components/contact/ContactForm.vue';
import ContactDetails from '@/components/contact/ContactDetails.vue';

export default {
	components: {
		//ContactForm,
		ContactDetails,
	},
	data: () => {
		return {
			contacts: [
				{
					id: 1,
					name: 'Eduard-Keil-Gasse 27 | 8041 Graz | Austria',
					icon: 'map-pin',
				},
				{
					id: 2,
					name: 'office@vimotion.at',
					icon: 'mail',
				},
				{
					id: 3,
					name: '+43 660 1884125',
					icon: 'phone',
				},
				{
					id: 4,
					name: 'UID-Nummer: ATU77634516',
					icon: 'globe',
				},				
			],
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

