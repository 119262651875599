<template>

      <Renderer ref="renderer" pointer resize="window" shadow @click="randomColors">
          <Camera :position="{ z: 4.5 }" :fov=40 />
          <Scene background="#5489b3" >
            <AmbientLight color="#666666" />
            <SpotLight
              color="#aaaaaa"
              :intensity="0.3"
              :position="{ x: 0, y: 3, z: 2 }"
              :angle="Math.PI / 2"
              :penumbra="0.1"
              cast-shadow :shadow-map-size="{ width: 1024, height: 1024 }"
            />
            <SpotLight
              color="#9999ff"
              :intensity="0.7"
              :position="{ x: 0, y: -3, z: 2 }"
              ,
              FilmPass:angle="Math.PI / 3"
              :penumbra="0.1"
              cast-shadow :shadow-map-size="{ width: 1024, height: 1024 }"
            />
            <CannonWorld :gravity="{ x: 0, y: 0, z: 0 }" @before-step="onBeforeStep">
              <InstancedMesh ref="imeshC" :count="COUNT" @created="initIMesh" cast-shadow receive-shadow>
                <SphereGeometry :radius="0.1" :width-segments="16" :height-segments="16" />
                <PhongMaterial />
              </InstancedMesh>
              <!-- <Sphere v-for="i in 2" :key="`sphere-${i}`" :radius="0.13" :position="{ x: i*1.75 - 3}" :width-segments="16" :height-segments="16" receive-shadow>
                <PhongMaterial />
              </Sphere> -->

              <Sphere ref="punkt1" :radius="0.13"  :width-segments="16" :height-segments="16" receive-shadow>
                <StandardMaterial   color="#ff4444" />
              </Sphere>

              <Sphere ref="punkt2" :radius="0.13"  :width-segments="16" :height-segments="16" receive-shadow>
                <PhongMaterial color="#ff4444"/>
              </Sphere>
              
              <!-- <Box :position="{ x: 0, y: -0.75, z: -3 }" :scale="{x:10,y:0.2,z:10}" ></Box>
              <Box :position="{ x: 0, y: 0.7, z: -3 }" :scale="{x:10,y:0.2,z:10}" ></Box>
               -->
              <FbxModel ref="vimotion" src='3d/vimotion.fbx'  @load="onLoad" >                          
              </FbxModel>
      
            </CannonWorld>
          </Scene>
          <EffectComposer>
            <RenderPass />
            <UnrealBloomPass :strength="0.45" :threshold="0.59" />
            <!-- <HalftonePass :radius="4" :scatter="0.02" /> -->
            <!-- <FilmPass></FilmPass> -->
            <FXAAPass />
          </EffectComposer>
      </Renderer>

</template>


<script>
import { Color, MathUtils, Object3D, Vector3 } from 'three';
import {
  AmbientLight,
  Camera,
  EffectComposer,
  FXAAPass,
  InstancedMesh,
  PhongMaterial,
  Renderer,
  RenderPass,
  Scene,
  Sphere,
//  Box,
  FbxModel,
  //GltfModel,
  SphereGeometry,
  SpotLight,
  UnrealBloomPass,
  StandardMaterial ,
  //HalftonePass,
  //FilmPass
} from 'troisjs';
import CannonWorld from 'troisjs/src/components/physics/CannonWorld.js';
import niceColors from 'nice-color-palettes';

const { randFloat: rnd, randFloatSpread: rndFS } = MathUtils;

export default {
	components: {
    AmbientLight,
    Camera,
    CannonWorld,
    EffectComposer,
    FXAAPass,
    InstancedMesh,
    PhongMaterial,
    Renderer,
    RenderPass,
    Scene,
    FbxModel,
    //GltfModel,
    Sphere,
  //  Box,
    SphereGeometry,
    SpotLight,
    UnrealBloomPass,
    StandardMaterial ,
    //HalftonePass,
    //FilmPass
},

data() {
    return {
      vimotion: null, // Declare the vimotion variable in the data section
      firstRenderingDone: false,
      lastPositionChangeTime: null, // Initialize lastPositionChangeTime
      lastSwapPositionChangeTime: null, // Initialize lastPositionChangeTime
      lastPosition: new Vector3(Infinity, Infinity, Infinity), // Initialisiere lastPosition
      lastClickedPosition: new Vector3(Infinity, Infinity, Infinity),
    };
  },

mounted() {
    const renderer = this.$refs.renderer;

    renderer.onBeforeRender(() => 
    {
      if (! this.firstRenderingDone)
      {
        this.handleResize();
        this.firstRenderingDone=true;
      }
    });

    renderer.onResize(() => 
    {
      this.handleResize();
    });
  },



	setup() {
    return {
      COUNT: 100,
    };
  },
  methods: {
    handleResize() { // Die Methode wird hier extrahiert
      if (this.vimotion) 
      {
        const val = Math.min(window.innerWidth * 0.002, 1.5);
        this.vimotion.scale.set(val, val, 0.4);
        const punkt1 = this.$refs.punkt1.mesh;
        punkt1.position.set(-1.13 * val ,0.33 * val,0.02);
        const pointSize = val * 0.85;
        punkt1.scale.set(pointSize, pointSize, pointSize);
        const punkt2 = this.$refs.punkt2.mesh;
        punkt2.position.set(0.75 * val ,0.33 * val,0.02);
        punkt2.scale.set(pointSize, pointSize, pointSize);
      }
      
      this.$refs.renderer.three.setSize(window.innerWidth, window.innerHeight/3);
     
    },

    onBeforeStep() {

      // Initialisiere lastPositionChangeTime beim ersten Aufruf
      if (!this.lastPositionChangeTime) {
        this.lastPositionChangeTime = Date.now();
        this.lastSwapPositionChangeTime = Date.now();        
      }  
      const clickedPosition = this.$refs.renderer.three.pointer.positionV3;
      const target = clickedPosition;

      if (!this.lastClickedPosition.equals(clickedPosition))
      { 
        //clickedPosition.copy(target);
        target.copy(clickedPosition);
        this.lastPositionChangeTime = Date.now();
        this.lastClickedPosition.copy(clickedPosition);
      }
      else
      {        
        if (Date.now() - this.lastPositionChangeTime > 5000) // 5000 Millisekunden = 5 Sekunden
        {
            //clickedPosition.copy(target);
            if (Date.now() - this.lastSwapPositionChangeTime < 5000)
            {
              target.copy(new Vector3(0, 1, 0));
            }
            else
            {
              target.copy(new Vector3(0, -1, 0));              
            }
            if (Date.now() - this.lastSwapPositionChangeTime > 10000)
            {
              this.lastSwapPositionChangeTime = Date.now();
            }
        }
      }
      const v = new Vector3();
      this.imesh.userData.bodies.forEach(body => {
        v.copy(target).sub(body.position).normalize().multiplyScalar(1);
        v.clampScalar(-0.5, 0.5);
        //v.clampScalar(-1, 1);
        body.force.copy(v);
      });

    },

    onLoad(object) {
      //this.$refs.renderer.three.setSize(1000, 100)
      this.vimotion = object;    
      this.firstRenderingDone = false,

      object.traverse(function (child) 
      {        
        if (child.isMesh) 
        {          
          child.castShadow = true;
          child.receiveShadow = true;
          child.scale.set(1,1,1);
          child.rotation.set(0,0,0);
          child.position.set(0,0,0);
          console.log(child);
        }   
      })   
     
      },

    initIMesh(imesh) {
      this.imesh = imesh;

      const dummy = new Object3D();
      const color = new Color();
      const scales = new Array(this.COUNT);
      const masses = new Array(this.COUNT);
      const colors = new Array(this.COUNT).fill().map(() => niceColors[10][Math.floor(rnd(0, 5))]);

      for (let i = 0; i < this.COUNT; i++) {
        dummy.position.set(rndFS(12), rndFS(12), rndFS(12));
        const scale = rnd(0.2, Math.min(window.innerWidth * 0.002 , 1));
        scales[i] = scale;
        masses[i] = scale*2;
        dummy.scale.set(scale, scale, scale);
        dummy.updateMatrix();
        imesh.setMatrixAt(i, dummy.matrix);
        imesh.setColorAt(i, color.set(colors[i]));
      }
      imesh.instanceMatrix.needsUpdate = true;
      imesh.userData.scales = scales;
      imesh.userData.masses = masses;
      imesh.userData.damping = 0.4;
     
    },
    randomColors() {
      /*
      const ci = Math.floor(rnd(0, 100));
      const color = new Color();
      const colors = new Array(this.COUNT).fill().map(() => niceColors[ci][Math.floor(rnd(0, 5))]);
      for (let i = 0; i < this.COUNT; i++) {
        this.imesh.setColorAt(i, color.set(colors[i]));
      }
      this.imesh.instanceColor.needsUpdate = true;
      */
    },
  },
	
};
</script>

