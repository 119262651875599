<script>
import AboutClientSingle from './AboutClientSingle.vue';

export default {
	components: {
		AboutClientSingle,
	},
	data: () => {
		return {
			clientsHeading: 'Firmen, mit denen ich arbeite bzw. gearbeitet habe:',
			clients: [
				{
					id: 1,
					title: 'BVAEB',
					img: require('@/assets/images/marken/bvaeb.png'),
				},
				{				
					id: 2,
					title: 'InnoPhore',
					img: require('@/assets/images/marken/innophore.webp'),
				},				
				{
					id: 3,
					title: 'EGGER',
					img: require('@/assets/images/marken/egger.webp'),
				},
				{
					id: 5,
					title: 'Haus der Wissenschaft',
					img: require('@/assets/images/marken/hdw.webp'),
				},
				{
					id: 6,
					title: 'Energie Steiermark',
					img: require('@/assets/images/marken/energie_steiermark.webp'),
				},
				{
					id: 4,
					title: 'NextSense',
					img: require('@/assets/images/marken/nextsense.webp'),
				},
				{
					id: 7,
					title: 'Wimmer',
					img: require('@/assets/images/marken/Wimmer.jpeg'),
				},
				{
					id: 7,
					title: 'Nokia-Siemens',
					img: require('@/assets/images/marken/Nokia_Siemens.png'),
				},
				// {
				// 	id: 8,
				// 	title: 'Fronius',
				// 	img: require('@/assets/images/marken/Fronius.png'),
				// },		
				{
					id: 9,
					title: 'OEBB',
					img: require('@/assets/images/marken/oebb.webp'),
				},	
				{
					id: 10,
					title: 'Rubikon',
					img: require('@/assets/images/marken/rubikon.webp'),
				},
				// {
				// 	id: 11,
				// 	title: 'Frauenhäuser Steiermark',
				// 	img: require('@/assets/images/marken/frauenhaus.png'),
				// },
				{
					id: 13,
					title: 'Ärztehaus Lend',
					img: require('@/assets/images/marken/aerztehaus_lend.webp'),
				},
				{
					id: 12,
					title: 'TIQA',
					img: require('@/assets/images/marken/tiqa.jpeg'),
				},
				{
					id: 14,
					title: 'Carry my car',
					img: require('@/assets/images/marken/carry_my_car.png'),
				},
			],
		};
	},
};
</script>

<template>
	<div class="mt-10 sm:mt-20">
		<p
			class="font-general-medium text-2xl sm:text-3xl text-primary-dark dark:text-primary-light"
		>
			{{ clientsHeading }}
		</p>
		<div class="grid grid-cols-2 sm:grid-cols-4 mt-10 sm:mt-14 gap-2 justify-center items-center">
    <AboutClientSingle
        v-for="client in clients"
        :key="client.id"
        :client="client"
         />
         </div>
	</div>
</template>


<style scoped>
/* Fügen Sie hier jede spezifische Stilregel für die AboutClientSingle-Komponente hinzu */
.about-client-single-container {
    background-color: white;
    padding: 10px; /* optional: Fügen Sie Padding hinzu, um die Bilder vom Rand zu trennen */
}
</style>