<script>
export default {
  props: ['client'],
};
</script>

<template>
  <div>
    <div class="image-wrapper">
      <img
        :src="client.img"
        :alt="client.title"
        class="w-64 py-5 px-8 border border-ternary-light dark:border-ternary-dark shadow-sm image-with-shadow"
      />
    </div>
  </div>
</template>

<style scoped>
.image-wrapper {
  background-color: rgb(255, 255, 255); /* Set the background color to white */
  display: inline-block; /* Ensure the wrapper only takes the necessary width */
  margin-bottom: 50px; /* Adjust the negative margin to cover the bottom curved border */
}
.image-with-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Hier wird der Schatten definiert */
}
</style>
