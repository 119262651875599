<template>
	<!-- <v-parallax :src="codeImage" style="margin-left: 0; margin-right: 0; width: 100%;"> -->
	<div class="d-flex flex-column fill-height  text-white">
		<div class="text-left overlay-container" style="margin: 40px">
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-4xl sm:text-left text-white dark:text-primary-light ">
				<br><br>
				<font size="+4">Was macht ViMotion ?</font>
			</h1>
			<br>
			<br>
			<p class="font-general-small mt-10 sm:text-xl  xl:text-1xl sm:text-left leading-none text-black ">
			<ul>
				<b class="text-white; text-container">
					<font size="+2">Visualisierung | AR | VR | 3D-Softwarentwicklung</font>
				</b>
				<br>
				<br>
				<div style="margin: 10px;">
					3D-Apps - Websites mit 3D-Inhalten - Visualisierung technischer Anlagen - interaktive Installationen in
					Kombination mit Hardware
				</div>
				<br>

				<br>
				<b class="text-white; text-container">
					<font size="+2">Websites | Mobile-Apps (Android / iOS) | native Anwendungen</font>
				</b>
				<br>
				<br>
				<div style="margin: 10px;">
					Backend-Lösungen und deren Anwendung auf der Frontend-Seite -
					Entwicklung und Realisation von
					kundenspezifischen Anwendungen.
				</div>
				<br>
			</ul>

			<i>Innovative Lösungen für herausfordernde Projekte zu finden ist mein Spezialgebiet.</i>
			<br>
			<br>
			<br>
			<center>
			<router-link @click="handleMenuItemClick" to="/projects" aria-label="Auszüge meiner Projekte" class="underline">
				Auszüge aus meinen Projekten
			</router-link>
			<br>
			<br>
			<router-link @click="handleMenuItemClick" to="/about" aria-label="Über mich" class="underline">
				Über mich
			</router-link>
		</center>
			</p>
		</div>

	</div>
	<!-- </v-parallax> -->
</template>


<script>
import feather from 'feather-icons';
import codeImage from '@/assets/images/code.jpeg';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
			codeImage: codeImage
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>


<style scoped>
::v-deep ul {
	list-style-type: disc;
	/* Hiermit stellen Sie Bullets wieder her */
}

.overlay-container {
	position: relative;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(253, 253, 253, 0.5);
	/* Adjust the color and opacity as needed */
	z-index: 1;
	/* Ensure the overlay is above the background image */
}

.text-container {
	font-smooth: always;
	/* Füge diese Eigenschaft hinzu, um Antialiasing für den Text zu aktivieren */
	color: rgb(240, 237, 237)
}

.with-text-shadow {
	text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.3);
	/* Ändere die Werte nach Bedarf */
}
</style>


