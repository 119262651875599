<script>
export default {
	props: ['project'],
};
</script>

<template>
	<!-- <router-link></router-link> -->
	<div :to="`/projects/single-project/${project.title}`" class="rounded-xl shadow-lg hover:shadow-xl mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark" aria-label="Single Project">
		<div>
			<img 
				:src="project.img"
				:alt="project.title"
				class="rounded-t-xl border-x-8"
			/>
		</div>
		<div class="text-center px-4 py-6">
			<p	class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2">
				{{ project.title }}
			</p>
			<p>
			<span class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light">
				{{ project.category }}
			</span>
			</p>
			<span class="text-l text-ternary-dark dark:text-ternary-light">
				{{ project.description }}
			</span>				
			
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
